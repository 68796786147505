.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

@keyframes alertOpen {
    0%  {transform: scale(0.2) rotate(3deg);}
    33% {transform: scale(0.7) rotate(-2deg);}
    66% {transform: scale(0.5) rotate(1deg);}
    100% {transform: scale(1) rotate(0deg);}
  }

.ReactModal__Content--after-open{
  animation-name: alertOpen;
  animation-duration: 0.5s;
}